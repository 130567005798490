import { tables } from "./tables";

// Carto configuration
export const user = "mpgranch";
export const sql_endpoint = `https://${user}.cartodb.com/api/v2/sql?`;

const envTables = tables["production"];

export const bee_table = envTables["bee_table"];
export const biocrust_data_table = envTables["biocrust_data_table"];
export const biocrust_species_list_table =
  envTables["biocrust_species_list_table"];
export const boundary_table = envTables["boundary_table"];
export const ebird_table = envTables["ebird_table"];
export const bird_taxa = "bird_taxa";
export const flora_detail_table = envTables["flora_detail_table"];
export const flora_species_list = envTables["flora_species_list"];
export const flora_voronoi_table = envTables["flora_voronoi_table"];
export const inaturalist_observations_table =
  envTables["inaturalist_observations_table"];
export const inaturalist_photos_table = envTables["inaturalist_photos_table"];
export const inaturalist_taxa_table = envTables["inaturalist_taxa_table"];
export const livecam_table = envTables["livecam_table"];
export const management_unit_table = envTables["management_unit_table"];
export const observation_points_table = envTables["observation_points_table"];
export const restoration_table = envTables["restoration_table"];
export const restoration_action_responses =
  envTables["restoration_action_responses"];
export const restoration_photo_responses =
  envTables["restoration_photo_responses"];
export const soil_data_table = envTables["soil_data_table"];
export const soil_station_table = envTables["soil_station_table"];
export const trailcam_cameras_table = envTables["trailcam_cameras_table"];
export const trailcam_photos_table = envTables["trailcam_photos_table"];
export const video_table = envTables["video_table"];
export const weather_data_table = envTables["weather_data_table"];
export const weather_station_table = envTables["weather_station_table"];
export const bee_taxa_table = envTables["bee_taxa_table"];
export const raptor_table = envTables["raptor_table"];

// Raptor aggregation configuration
export const raptorBbox = [
  [-114.1145224, 46.6112703],
  [-113.9260327, 46.8414853]
];

// map tile URLs
export const customAerialBaseURL = "https://d3awo58cp1rkfe.cloudfront.net";
export const zxy = "{z}/{x}/{y}.png";

export const customAerial2015 = `${customAerialBaseURL}/2015/${zxy}`;
export const customAerial2016 = `${customAerialBaseURL}/2016/${zxy}`;
export const customAerial2017 = `${customAerialBaseURL}/2017_ranch_color/${zxy}`;
export const customAerial2018 = `${customAerialBaseURL}/2018/${zxy}`;
export const customAerial2020 = `${customAerialBaseURL}/2020/${zxy}`;
export const customAerial2021 = `${customAerialBaseURL}/2021/${zxy}`;
export const customAerialNorth2016 = `${customAerialBaseURL}/2016-north/${zxy}`;
export const avgSolarIns = `${customAerialBaseURL}/avg_slr/${zxy}`;
export const ndvi2015 = `${customAerialBaseURL}/2015-NDVI/${zxy}`;
export const mapStackTerrain = `https://tiles.stadiamaps.com/tiles/stamen_terrain/${zxy}`;
export const fstopo = `${process.env.PUBLIC_URL}/data/tiles/fstopo/{z}/{x}/{y}.png`;

export const naip = `https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}`;
export const hillshade = `https://basemap.nationalmap.gov/arcgis/rest/services/USGSShadedReliefOnly/MapServer/tile/{z}/{y}/{x}`;
export const elevationTiles = `${process.env.PUBLIC_URL}/data/tiles/elevation/{z}/{x}/{y}.png`;

export const mpgInsetCenter = [46.7024965, -113.9984147];
export const mpgCenter = [46.7094965, -113.9984147];
export const mpgZoom = 13;

export const northCenter = [47.5231347, -113.6710915];
export const northInsetCenter = northCenter;
export const northZoom = 16;

export const backendUrl = "https://mpg-habitat-report-builder.herokuapp.com";
export const authCheckKeyUrl = `${backendUrl}/api/keys/check`;
export const disableTrailcamImageUrl = `${backendUrl}/api/buckeye-photo/delete`;
